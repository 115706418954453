import React from "react"
import styled from "@emotion/styled"
import MakerArray from './datas/makerAll';
import BasicTable from './basicTable';
import AccentBox from './accentBox';
import SmallBox from './smallBox';
import Img from "./image";
import { mq, color, contentWidth } from "../utils/style";
import { Link } from "gatsby";
import { navigate } from '@reach/router';

const InfoTable = (meta, props) => {
  return (
    <>
      <Img {...props} name={meta.name} alt={meta.fname}/>
      <BasicTable className={'center top-margin'}>
        <tbody>
          <tr>
            <th>コスパ</th>
            <th>デザイン</th>
            <th>サポート</th>
            <th>人気</th>
          </tr>
          <tr>
            <td>{meta.cospa}</td>
            <td>{meta.design}</td>
            <td>{meta.support}</td>
            <td>{meta.popular}</td>
          </tr>
        </tbody>
      </BasicTable>
    </>
  )
};

const Head = props => {
  return <h3><span className="rank"/>{props.title}</h3>
};

class Maker extends React.PureComponent {
  render() {
    const { meta } = this.props;
    switch(meta.name) {
      case 'mouse':
        return (
          <div id={meta.name}>
            <Head title="マウスコンピュータ | 安心感とコスパの絶妙なバランス"/>
            {InfoTable(meta, this.props)}
            <p>乃木坂のCMにより有名となった株式会社マウスコンピュータが運営するパソコンストア。東京都中央区に本社があり、液晶モニターメーカーであるイーヤマを吸収合弁しておりiiyamaブランドのモニター販売も手がける。</p>
            <p>ノートパソコンは特に<strong><b>14インチの準モバイルタイプのノートが狙い目</b>のモデル</strong>であり、作業や持ち運びのしやすさ、バッテリー性能、価格のバランスが非常に優れている。</p>
            <p>他にも<strong><b>目的ごとに幅広いラインナップを揃えている</b></strong>ため、容易に自分に合ったパソコンを探すことができるだろう。</p>
            <p>また一部上場企業で国内生産をしており、パソコンに詳しくない人もターゲットとしているため、信頼性や<strong><b>24時間365日サポート</b></strong>など体制も厚い。</p>
            <p>コスパも悪くないため、はじめてネットでパソコンを購入するというケースでは特におすすめしたいメーカーである。</p>
            <AccentBox title="サブブランド">
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886902210" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886902210" height="1" width="1" border="0" alt=""/>G-Tune</a>: マウスのゲーミングブランド。ハイスペックタワー型から軽量10時間駆動ノートなど製品力も高い。</li>
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886902208" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886902208" height="1" width="1" border="0" alt=""/>DAIV</a>: クリエイター向けのブランド。Adobe After Effectsを使用する人や3Dモデリングを行う人などプロのクリエイティブ作業に最適なモデル。</li>
            </AccentBox>
            <SmallBox type="word accent" text="公式サイトへ">
              <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847755" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847755" height="1" width="1" border="0" alt=""/>マウスコンピュータ</a>
            </SmallBox>
          </div>
        )
      case 'koubou':
        return (
          <div id={meta.name}>
            <Head title="パソコン工房 | リーゾナブルで抜群のスペック"/>
            {InfoTable(meta, this.props)}
            <p>信頼性が高いBTOの老舗メーカー。パソコンの中級者が好んで購入する。</p>
            <p>実はマウスコンピュータ系列の会社であるものの運営・商品開発は全く別である。マウスコンピュータと比べ広告宣伝費を抑えられるのかコストパフォーマンスが高い。</p>
            <p>モバイルノートの取り扱いはないが、<strong><b>スタンダードで高品質なノートパソコン</b></strong>を検討しているならば予算も抑えられ検討しても良いだろう。</p>
            <SmallBox type="word accent" text="公式サイトへ">
              <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000214&type=3&subid=0" rel="nofollow noopener" target="_blank" >パソコン工房 -ノートパソコン-</a><img width="1" height="1" alt="" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000214&type=3&subid=0"/>
            </SmallBox>
          </div>   
        )
      case 'lenovo':
        return (
          <div id={meta.name}>
            <Head title="Lenovo | IBMから分離した世界シェアトップメーカー"/>
            {InfoTable(meta, this.props)}
            <p>IBMから分離した世界最大シェアを誇る中国メーカー。世界シェア25%を誇り、部品の大量発注と量産体制による<strong><b>コストパフォーマンスは群を抜く</b></strong>。</p>
            <p>ビジネス用のThink、コスパのIdeaPad、スタイリッシュなYoga、ゲーミングのLEGIONと<strong><b>ラインナップも豊富</b></strong>。</p>
            <p>ブランドとしては、もともとIBMのパソコン部門だっただけのことはあり<strong><b>セキュリティ面で一日の長がある</b></strong>ため法人向けとしての需要も高い。</p>
            <AccentBox title="サブブランド">
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Fthinkpad%2F%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt=""/>ThinkPad</a>: ビジネス向きの信頼性とセキュリティが高いノートパソコン、テレワーク、在宅ワークにはおすすめの一台。</li>
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Fnotebooks%2Fideapad%2Fc%2Fideapad%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt=""/>IdeaPad</a>: 驚愕のコストパフォーマンス。<Link to="/note-cospa/">コスパ最強ノートパソコン</Link>の一つ。予算を抑えてつつサクサク動く。</li>
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Fyoga%3Fcid%3Djp%3Aaffiliate%3Adsry7f"  target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt=""/>Yoga</a>: IdeaPadがダサいと感じた人のためのスリムベゼルモデル。スタイリッシュでコスパも良好。長時間バッテリー、2 in 1モデルなどバリエーションも豊富。</li>
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Flegion%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt=""/>LEGION</a>:コスパ最強のゲーミングPC、本格的な動画編集向けに購入する人もいる。</li>
            </AccentBox>
            <SmallBox type="word accent" text="公式サイトへ">
              <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886331569&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fnotebooks%2Fc%2Fnotebooks%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886331569" height="1" width="0" border="0" alt=""/>Lenovo ノートパソコン</a> | <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887944551&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fmembers%2Fja%2Fd%2Fdeals%2Fpromo-offers%2F%3FgroupId%3Djpad%26cid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887944551" height="1" width="0" border="0" alt=""/>キャンペーン</a>
            </SmallBox>
          </div>
        )
      case 'dell':
        return (
          <div id={meta.name}>
            <Head title="デル | コスパの黒船、圧倒的価格競争力"/>
            {InfoTable(meta, this.props)}
            <p>アメリカ合衆国のテキサス州に本社を置く大手パソコンメーカーで世界シェアも常にトップグループに入る。</p>
            <p>Lenovoはコスパの良いモデルに悪いモデルが紛れ込んでいるイメージだが、<strong>デルは安定して<b>全ての製品でコスパが高い</b></strong>ため、安心して購入できる。</p>
            <p>特に10万円以下でそこそこ高性能なノートパソコンが欲しいという時に重宝するメーカーが私の中でのイメージ。</p>
            <p>ただ、基本的にパソコンは海外から運ばれるため、納期は遅めで、またサポートも訛りのある日本語だったりするので注意が必要なこともある。</p>
            <AccentBox title="サブブランド">
              <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10008673&type=3&subid=0" rel="nofollow noopener" target="_blank">Inspiron</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10008673&type=3&subid=0" />: デルの超コスパ売れ筋モデル。<Link to="/note-cospa/">コスパ最強ノートパソコン</Link>のもう一つ。LENOVOのIdeaPadと比較して甲乙つけがたいところ。</li>
              <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10009516&type=3&subid=0" alt="" rel="nofollow noopener" target="_blank">XPS</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10009516&type=3&subid=0" />: デルのフラッグシップモデル。主に家庭内で使うハイスペックなノートパソコンが欲しい場合は特におすすめ。</li>
            </AccentBox>
            <SmallBox type="word accent" text="公式サイトへ">
              <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10006794&type=3&subid=0" target="_blank" rel="nofollow noopener">デル【個人向け】ノートパソコン一覧</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10006794&type=3&subid=0" /> | <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10005295&type=3&subid=0"　target="_blank" rel="nofollow noopener">広告掲載・売れ筋モデルへ</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10005295&type=3&subid=0" />
            </SmallBox>
          </div>
        )
      case 'frontier':
        return (
          <div id={meta.name}>
            <Head title="フロンティア | ゲーミングノートのコスパで存在感"/>
            {InfoTable(meta, this.props)}
            <p>BTOの草分け的なブランド。もともとはフロンティア神代という会社だったが、経営の悪化からヤマダ電気の完全子会社となり、同様にヤマダ子会社のインバースが事業を引き継いでいる。</p>
            <p>デスクトップパソコンで有名なメーカーであるが、<strong><b>ミドルレンジ以上のゲーミングノートも優秀</b></strong>であり、その他14インチで軽量・長時間駆動な高性能ノートは製品力が高い。</p>
            <p><strong><b>セール品はコスパが高い</b></strong>ので、狙ったモデルでセール品がないかをチェックする事が重要となるだろう。</p>
            <SmallBox type="word accent" text="公式サイトへ">
              <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886332066&vc_url=https%3A%2F%2Fwww.frontier-direct.jp%2Fdirect%2Fc%2Fcnote%2F" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886332066" height="1" width="0" border="0" alt=""/>FRONTIERダイレクト</a> | <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886332066&vc_url=https%3A%2F%2Fwww.frontier-direct.jp%2Fdirect%2Fc%2Fcsaleinfo%2F" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886332066" height="1" width="0" border="0" alt=""/>セール・特集</a>
            </SmallBox>
          </div>
        )
      case 'fujitsu':
        return (
          <div id={meta.name}>
            <Head title="富士通 | 世界最軽量のモバイル性能が突出"/>
            {InfoTable(meta, this.props)}
            <p><strong>外出先で使う機会が多いならば特に検討したいメーカーが富士通</strong>である。13.3インチの最軽量モデルは僅か600グラム代であり、<strong><b>タブレットを持ち歩くような感覚でパソコンを携帯</b></strong>できてしまう。</p>
            <p>軽量化を必要な端子などの機能を削るのではなく、先端素材のマグネシウムリチウム合金や基盤レベルの最適化を駆使することで実現している点は素晴らしいの一言に尽きる。</p>
            <p>また、富士通はアローズをはじめデザインのダサさにはもともと定評があるが、<strong>ノートパソコンに関しては<b>デザインが洗練</b></strong>されており、生活に溶け込むようなスタイリッシュなものも多い。</p>
            <p>軽量モデルの他、国内産の洗練されたパソコンを求めているならば検討の余地があるだろう。シークレットクーポンもあるため購入時には利用してほしい。</p>
            <AccentBox title="LIFEBOOK ピックアップ">
              <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=815914.10003619&type=3&subid=0" target="_blank" rel="nofollow noopener">UHシリーズ</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=815914.10003619&type=3&subid=0" />: 634g~の超軽量でかつ耐久性にも優れたモバイルノート。売れ筋No1モデルでビジネスマンの他、キャンバスで持ち歩く必要がある大学生からの人気も高い</li>
              <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=815914.10014668&type=3&subid=0" target="_blank" rel="nofollow noopener">CHシリーズ</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=815914.10014668&type=3&subid=0" />: 有機ELディスプレイを搭載した美しいモニターとスタイリッシュな外観が際立つ持ち運びたくなるパソコン。カラーバリエーションも豊富</li>
              <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=815914.10014667&type=3&subid=0" target="_blank" rel="nofollow noopener">THシリーズ</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=815914.10014667&type=3&subid=0" />: 15.6インチで1.39kg、メッシュ状のX-TEXTUREなどインテリアに溶け込みリビングでサッと使えるノートパソコン</li>
            </AccentBox>
            <SmallBox type="word accent" text="公式サイトへ">
              <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=183092.10000188&type=3&subid=0" target="_blank" rel="nofollow noopener">富士通FMV ノートPC一覧</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=183092.10000188&type=3&subid=0"/> | <Link to="/fujitsu/" rel="nofollow">シークレットクーポン</Link>
            </SmallBox>
          </div>
        )
      case 'hp':
        return (
          <div id={meta.name}>
            <Head title="HP | デザイン性とコスパのハイレベルな両立"/>
            {InfoTable(meta, this.props)}
            <p><strong>デザイン性が高く、コスパも良いメーカー</strong>がシリコンバレーに本社を構えるHP(ヒューレッドパッカード)である。</p>
            <p>他の海外メーカーであるデル、Lenovoと同様世界で高いシェアを持ち、ゲーミングから一体型デスクトップ、モバイルノートまで<strong><b>全方位</b>の製品ラインナップ</strong>を手がける。</p>
            <p><strong>特に<b>13.3インチのモバイルノート</b>は人気が高く</strong>、デルやLenovoより少し高くても良いから洗練されたデザインのパソコンが欲しいという層から支持は絶大である。</p>
            <AccentBox title="商品ピックアップ・サブブランド">
              <li><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=854073.1406&type=3&subid=0" rel="nofollow noopener" target="_blank">HP ENVY x360 13</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=854073.1406&type=3&subid=0"/>: 360度回転できる13インチコンバーチブルモデル。手頃な価格とおしゃれなデザインで人気を誇る</li>
              <li><a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=35909&murl=https%3A%2F%2Fjp.ext.hp.com%2Fnotebooks%2Fpersonal%2Fpavilion%2F" rel="nofollow noopener" target="_blank">Pavilion</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=854073.1&type=10"/>: エントリークラスのスタンダードノートパソコン、初めての人にも安心できる一台</li>
            </AccentBox>
            <SmallBox type="word accent" text="公式サイトへ">
              <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=252926.29&type=3&subid=0" target="_blank" rel="nofollow noopener">HP ノートパソコン</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.29&type=3&subid=0" />
            </SmallBox>
          </div>
        )
      case 'nec':
        return (
          <div id={meta.name}>
            <Head title="NEC | 国内シェアトップで安心の使い勝手"/>
            {InfoTable(meta, this.props)}
            <p><strong>国内シェアは20%超</strong>でトップを走るNEC。ノートパソコンの品質・サポート対応・デザインどれをとっても高いレベルである。</p>
            <p>残念ながらコスパはあまり高くはない。しかし<strong><b>初心者に優しい初期ソフト</b>、<b>懇切丁寧な説明書</b>、<b>充実したWebサイトでのQ&A</b>など簡単にパソコンを使うための工夫</strong>が随所にみられ、購入後にやりたいことができなくて困るという事は少ないであろう。</p>
            <p>製品力も然ることながら、この<strong>安心感が国内の高いシェアに繋がっている</strong>のである。</p>
            <p>またビジネス用ノートでは軽量でハイスペックなパソコンを販売していて、こちらは上級者にもおすすめできる。</p>
            <AccentBox title="LAVIE ピックアップ">
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=https%3A%2F%2Fwww.nec-lavie.jp%2Fproducts%2Fns%2Findex.html%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt=""/>LAVIE Note Standard</a>: 15.6/17.3インチのスタンダードノート、初心者用ナビゲーションが充実しておりスマホではちょっと物足りないと感じている人がスムーズにパソコンに移行できる。</li>
              <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=https%3A%2F%2Fwww.nec-lavie.jp%2Fproducts%2Fpm%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag"  target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt=""/>LAVIE Pro Mobile</a>: NECのプレミアムモバイルモデル。900gを切る軽量さに加え最長20時間超のバッテリー駆動という抜群のハードウェア性能。ビジネスマンへの人気は高い。</li>
            </AccentBox>
            <SmallBox type="word accent" text="公式サイトへ">
              <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=https%3A%2F%2Fwww.nec-lavie.jp%2Fshop%2Findex.html%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt=""/>NEC パソコンTop</a> | <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=https%3A%2F%2Fwww.nec-lavie.jp%2Fshop%2Fcampaign%2Fadcoupon%2F%3Fsc_cid%3Djp%3Aaffiliate%3Axrgx2p%26sc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt=""/>限定優待クーポン</a>
            </SmallBox>
          </div>
        )
      case 'dynabook':
        return (
          <div id={meta.name}>
            <Head title="Dynabook | シャープと東芝の融合、最強のモビリティ性能"/>
            {InfoTable(meta, this.props)}
            <p>世界初のノートパソコンを発売した技術力を持つ東芝と、鴻海の製造力や低消費電力のIGZO液晶を持つシャープによる合弁会社として誕生したのがDynabookである。ノートパソコン専業で、デスクトップは撤退している。</p>
            <p>特にモバイルノートの完成度は非常に高く、<strong>軽く、薄く、コンパクトでありながら堅牢性や長時間駆動というモビリティに重要な性能も高いレベルで実現していることから<b>最強のモバイルノート</b></strong>と名高い。</p>
            <p>大手国内メーカーならではのサポートも手厚く、安心して購入できることから大学生向けのパソコンとしても人気が高い。</p>
            <p>下記特設サイトにより5500円安く購入が可能である。【ID:dyna204cls PASS:T8Y7GRSV】</p>
            <SmallBox type="word accent" text="公式サイトへ">
              <a href="https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10001204&type=3&subid=0" target="_blank" rel="nofollow">Dynabook Top</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10001204&type=3&subid=0" /> | <a href="https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10001860&type=3&subid=0" target="_blank" rel="nofollow">特設サイトへ</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10001860&type=3&subid=0" />
            </SmallBox>
          </div>
        )
      case 'asus':
        return (
          <div id={meta.name}>
            <Head title="ASUS | 世界シェアトップのゲーミングノート"/>
            {InfoTable(meta, this.props)}
            <p>台湾に本社を置くパソコンメーカー。エイスースと読む。パソコンの土台となるマザーボードで世界No1のシェアを占めるなど製品開発力が高いメーカーである。</p>
            <p>特に<strong><b>ゲーミングノートの国内シェアはNo1</b></strong>であり、冷却性能の向上を考えて液体金属を採用したROG Strixなど勝つための細かいこだわりが見られる。</p>
            <p>また、<strong>高級機種Zenbook/Expertbookは<b>ノートパッドにテンキーを表示する</b>作り</strong>のため、テンキーのためにモバイルノートを諦めていた人には良い選択肢となる。</p>
            <p>公式サイトもあるが、基本的にAmazonの方が安いのでそちらで購入すればよいだろう。</p>
            {/* <p>他、Amazon経由での購入も多いが、<strong>シリアルナンバーさえ残っていれば<b>故意に壊そうとも保証が受けられる</b>ASUSの安心保障</strong>は公式ストアの魅力の一つであるので両方見ると良いだろう。</p>
            <AccentBox title="サブブランド">
              <li><a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=43708&murl=https%3A%2F%2Fjp.store.asus.com%2Fstore%2Fasusjp%2Fja_JP%2Flist%2FcategoryID.5001803600" rel="nofollow noopener" target="_blank">ASUS ExpertBook</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=858354.1&type=10"/>: フラッグシップモデル、軽量かつノートパッドでテンキーが使えるなど工夫を凝らした使いやすさを追求。極めつけは30時間超の異次元スタミナバッテリー</li>
              <li><a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=43708&murl=https%3A%2F%2Fjp.store.asus.com%2Fstore%2Fasusjp%2Fja_JP%2Flist%2FcategoryID.4889374200" rel="nofollow noopener" target="_blank">Zenbook</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=858354.1&type=10"/>: 日本の禅からヒントを得たASUSの代表的なプレミアムパソコン。バッテリー、モビリティ性能等全てが高レベルでかつコスパがそこそこ高い</li>
            </AccentBox> */}
            {/* <SmallBox type="word accent" text="公式サイトへ">
              <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=43708&murl=https%3A%2F%2Fjp.store.asus.com%2Fstore%2Fasusjp%2Fja_JP%2Flist%2FcategoryID.4887373900" target="_blank" rel="nofollow noopener">ASUS PC Store</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=858354.1&type=10"/> | <a href="https://amzn.to/2ZCu7DE" target="_blank" rel="nofollow noopener">ASUS ノートパソコン by Amazon</a>
            </SmallBox> */}
            <SmallBox type="word" text="販売サイトへ">
            <a href="https://amzn.to/3YthsAn" target="_blank" rel="nofollow noopener">Amazon公式販売ページ</a>
            </SmallBox>
          </div>
        )
      case 'microsoft':
        return (
          <div id={meta.name}>
            <Head title="Microsoft | Office標準付属と質感を極めた高級感"/>
          {InfoTable(meta, this.props)}
          <p><strong>旧来のパソコンにデザイン性をプラスしたパソコン界のダイソン</strong>でありモバイルノートSurfaceを中心に販売している。持ち歩く事が多いモバイルノートにおいて、デザインは重要な要素である。</p>
          <p>カフェマックはテンプレ過ぎて新鮮さに欠けるため、カフェサーフェスの方がおしゃれな感はある。<strong>特に<b>女性と大学生の間で人気が高い</b></strong>。</p>
          <p>タッチパネルやペンの感度も良好であり、新しいパソコンの使い方を模索している人にもおすすめできるかもしれない。</p>
          <p>また、<strong><b>Microsoft Office Home & Businessが標準で付属する</b>ので、オフィスソフト込みで考えるとコスパは妥協圏か。</strong>。</p>
          <SmallBox type="word accent" text="公式サイトへ">
            <a href="https://www.microsoft.com/ja-jp/surface" rel="nofollow noopener" target="_blank" >マイクロソフト</a>
          </SmallBox>
          </div>
        )
      case 'apple':
        return (
          <div id={meta.name}>
            <Head title="Apple(Macbook) | 他社を圧倒するブランド力"/>
          {InfoTable(meta, this.props)}
          <p>MacOSを搭載し、ハードウェアからソフトウェアまで一貫して手がけており、デザインや安定した性能には定評がある。</p>
          <p>Windowsとは異なるため使い勝手は独特で、<strong>右クリックが無い、ディレクトリの構造が分かりにくいなど<b>とっつきにくい点が多く</b></strong>、Windowsに慣れた人は注意が必要となる。
          </p>
          <p>しかしノートパッドの感触が心地が良く、特にアプリの切り替え操作が直感的に行いやすいため、そのような作業が多い<strong><b>プログラマーやクリエイティブな職種を中心として人気が高い</b></strong>。</p>
          <SmallBox type="word accent" text="公式 / 販売サイトへ">
          <a href="https://www.apple.com/jp/mac/" target="_blank" rel="nofollow noopener">Apple Store</a> | <a href="https://amzn.to/30p8CW7"  target="_blank" rel="nofollow noopener">Amazonで見る</a> | <a href="https://hb.afl.rakuten.co.jp/hgc/0ca19ae3.d60bbcdb.19675804.52da794d/?pc=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2Fmacbook%2F100040%2F"  target="_blank" rel="nofollow noopener">楽天で見る</a>
          </SmallBox>
          </div>
        )
      case 'vaio':
        return (
          <div id={meta.name}>
          <Head title="VAIO | ビジネスに特化した端子が豊富なノートパソコン"/>
          {InfoTable(meta, this.props)}
          <p>ソニーから分離された長野県安曇野に本社を置くパソコンメーカー。</p>
          <p><strong><b>売れ筋は12.5インチの軽量ノートパソコン</b>。パソコンを外部に持ち運び、プレゼンを行うようなサラリーマンを特に対象</strong>としている。</p>
          <p>小さいボディにDVI端子、複数のUSB端子、LANケーブルなど<strong><b>豊富なインターフェース</b>を備えビジネスシーンのあらゆる環境に適用できる</strong>事が最大の魅力と言える。</p><p>ちなみに私の会社用パソコンもVAIOが標準となっている。ビジネスシーンでは便利なパソコンである。</p>
          <SmallBox type="word accent" text="公式サイトへ">
            <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=596216.7&type=3&subid=0&LSNSUBSITE=LSNSUBSITE" target="_blank" rel="nofollow noopener">VAIOストア</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=596216.7&type=3&subid=0" />
          </SmallBox>
          </div>
        )
      case 'lg':
        return (
          <div id={meta.name}>
            <Head title="LG | 17インチ1350gの大型軽量パソコン"/>
          {InfoTable(meta, this.props)}
          <p>テレビや液晶モニターで定評がある韓国メーカー。日本でも高画質なテレビを低価格で販売しており見かける人も多いだろう。</p>
          <p>液晶の低消費電力技術を活かして<b>軽量でロングバッテリーのgram</b>というパソコンを販売している。<strong><b>17インチのノートパソコンで1350グラム</b>は常識はずれの軽さ</strong>である。</p>
          <p>ロングバッテリーを搭載しているため、作業のしやすい大型のノートパソコンを外でも使いたいという需要に答えてくれるだろう。</p>
            <SmallBox type="word accent" text="公式サイトへ">
            <a href="https://amzn.to/3hbDb8p" target="_blank" rel="nofollow noopener">Amazonで見る</a> | <a href="https://hb.afl.rakuten.co.jp/hgc/0ca19ae3.d60bbcdb.19675804.52da794d/?pc=https%3A%2F%2Fsearch.rakuten.co.jp%2Fsearch%2Fmall%2Flg%2Bgram%2B%25E3%2583%258E%25E3%2583%25BC%25E3%2583%2588%25E3%2583%2591%25E3%2582%25BD%25E3%2582%25B3%25E3%2583%25B3%2F" target="_blank" rel="nofollow noopener">楽天で見る</a>
            </SmallBox>
          </div>
        )
        case 'tsukumo':
          return (
            <div id={meta.name}>
              <Head title="TSUKUMO | ３万円台からのeX.computer"/>
              {InfoTable(meta, this.props)}
              <p>2009年にヤマダ電機に買収されたパソコンメーカーで、秋葉原にて企画、設計を行っている。最新パーツをいち早くパソコンに搭載し、価格も低価格。パソコンパーツのショップとしても有名である。</p>
              <p>コスパがとりわけ高いわけではないが、<strong>初音ミクやVtuberとの<b>キャラクターコラボ</b>が多く</strong>気になる存在である。</p>
              <p>他社製品も扱うが、自社ブランドとしてeX.computerというシリーズを販売しており、<strong>ノートパソコンは<b>3万円台からレンジに合わせて手軽に購入できる</b></strong>。</p>
              <SmallBox type="word accent" text="公式サイトへ">
              <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887072365&vc_url=https%3A%2F%2Fwww.tsukumo.co.jp%2Fbto%2Fpc%2Fnote%2F" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887072365" height="1" width="0" border="0" alt=""/>TSUKUMO</a>
              </SmallBox>
            </div>
          )
      default:
        return <></>;
    }
  }
}

const SelectBox = styled.div({
  input: {
    display: 'none',
  },
  'input:checked + label': {
    color: '#fff',
    backgroundColor: '#25A298',
    borderColor: 'black',
    fontWeight: 'bold',
  },
  label: {
    display: 'inline-block',
    fontSize: '1.5rem',
    lineHeight: '1.5',
    margin: '5px 2px 5px 0px',
    padding: '0.3em 1em',
    backgroundColor: '#eee',
    color: '#444',
    borderRadius: '5px',
    cursor: 'pointer',
  }
});

const TableScroller = styled.div({
  maxWidth: contentWidth,
  position: 'relative',
  table: {
    display: 'block',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    [mq('mobile')]: {
      lineHeight: '30px',
    },
    'th,td': {
      '&:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 1,
      }
    }
  }
});

const RankWrapper = styled.div({
  '.rank:before' : {
    counterIncrement: 'head',
    content: `counter(head)"位:"`
  }
});

export default class NoteMakerRanking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radios: {
        note: "na",
      },
      checkboxes: {
        cospa: false,
        design: false,
        support: false,
        business: false,
        light: false,
      }
    };
    // 人気順に並び変える
    // ラジオボタンの初期値をセット
  }

  _renderMakersTable = makerArray => {
    return (
      <TableScroller>
        <BasicTable className="nb center full-width">
          <thead>
            <tr>
              <th>メーカー</th>
              <th>コスパ</th>
              <th>デザイン</th>
              <th>サポート</th>
              <th>人気</th>
              <th>特徴</th>
            </tr>
          </thead>
          <tbody>
          {makerArray.map((maker, i) => {
            return (
              <tr key={maker.name}>
                <td align="left"><span style={{color: color.main, cursor: 'pointer'}}onClick={() => navigate(`#${maker.name}`)}>{i+1}位:</span><span dangerouslySetInnerHTML={{__html: maker.url}} /></td>
                <td>{maker.cospa}</td>
                <td>{maker.design}</td>
                <td>{maker.support}</td>
                <td>{maker.popular}</td>
                <td align="left">{maker.description}</td>
              </tr>
            )
          })}
          </tbody>
        </BasicTable>
      </TableScroller>
    )
  }

  handleChange = e => {
    const { name, value, checked } = e.target;
    if (name === 'note') {
      this.setState({
        radios: {
          ...this.state.radios,
          [name]: value,
        }
      });
    } else {
      this.setState({
        checkboxes: {
          ...this.state.checkboxes,
          [name]: checked
        }
      })
    }
  }

  createMakerArray = () => {
    const { note } = this.state.radios;
    const { cospa, design, support, business, light} = this.state.checkboxes;
    let makerArray = MakerArray.filter((maker) => {
      if (!maker.hasNote) {
        return false;
      }
      switch (note) {
        case 'na':
          return maker.hasNote;
        case 'standard':
          return maker.hasStandard !== false;
        case 'mobile':
          return maker.hasMobile;
        case 'gaming':
          return maker.hasGamingNote;
        default:
          break;
      }
      return false;
    });
    makerArray.sort((makerA, makerB) => {
      let score = 0;
      score += cospa ? makerB.cospa - makerA.cospa : 0;
      score += design ? makerB.design - makerA.design : 0;
      score += support ? makerB.support - makerA.support : 0;
      if (note === 'mobile') {
          if (light) {
              score += makerB.mobileBonus - makerA.mobileBonus;
          }
      }
      if (note === 'gaming') {
        score += makerB.gamingnoteBonus - makerA.gamingnoteBonus;
      }
      score += business ? makerB.businessBonus - makerA.businessBonus : 0;
      if (score === 0) {
          score = makerB.popular - makerA.popular;
      }
      return score;
    });
    return makerArray;
  }

  render() {
    const { note } = this.state.radios;
    const { cospa, design, support, business, light} = this.state.checkboxes;
    const makerArray = this.createMakerArray();
    return (
      <>
        <SelectBox className="mobile-margin">
          <div id="noteDetail">
            ノートの種類：
            <input name="note" type="radio" id="notena" value="na" checked={note === "na"} onChange={this.handleChange}/>
            <label htmlFor="notena">未定</label>
            <input name="note" type="radio" id="mobile" value="mobile" checked={note === "mobile"} onChange={this.handleChange}/> 
            <label htmlFor="mobile">モバイル(~13.5)</label>
            <input name="note" type="radio" id="standard" value="standard" checked={note === "standard"} onChange={this.handleChange}/>
            <label htmlFor="standard">スタンダード(14~)</label>
            <input name="note" type="radio" id="gaming" value="gaming" checked={note === "gaming"} onChange={this.handleChange} />
            <label htmlFor="gaming">ゲーミング</label>
          </div>
          <div>
            こだわり：
            <input name="cospa" id="cospa" type="checkbox" checked={cospa} onChange={this.handleChange}/>
            <label htmlFor="cospa">コスパ</label>
            <input name="design" id="design" type="checkbox" checked={design} onChange={this.handleChange}/>
            <label htmlFor="design">デザイン</label>
            <input name="support" id="support" type="checkbox" checked={support} onChange={this.handleChange}/>
            <label htmlFor="support">サポート</label>
            <input name="business" id="business" type="checkbox" checked={business} onChange={this.handleChange}/>
            <label htmlFor="business">ビジネス</label>
            <input name="light" id="light" type="checkbox" checked={light} onChange={this.handleChange}/>
            <label htmlFor="light">軽量</label> 
          </div>
        </SelectBox>
        {this._renderMakersTable(makerArray)}
        <RankWrapper>
        {makerArray.map(maker => {
          return <Maker {...this.props} meta={maker} key={maker.name}/>
        })}
        </RankWrapper>
      </>
    )
  }
}